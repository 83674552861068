<template>
  <v-container my-10>
    <v-card>
      <v-row>
        <v-card-title class="ml-4">
          <span class="headline">Bookings</span>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mt-4 mr-8" dark @click="createNewBooking"
          >New booking</v-btn
        >
      </v-row>
      <v-card-text class="pb-0">
        <v-row>
          <!-- <v-col cols="4" class="ml-5" v-if="boats.length > 5">
            <single-boat-selector
              @change="handleBoatSelection"
              :show-boat-name="true"
              label="Filter by boat"
            ></single-boat-selector>
          </v-col> -->
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-row justify="center" v-if="!loading">
              <v-progress-circular
                :size="55"
                :width="4"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
            <div>
              <div id="bookings" ref="test" style="margin:20px;"></div>
              <div id="msg_div"></div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <inline-booking-dialog
      v-if="showDialog"
      :booking-uuid="selectedBookingUuid"
      :show-dialog.sync="showDialog"
      @saved="loadBookings"
      @remove="loadBookings"
    ></inline-booking-dialog>

    <!-- <v-dialog
      v-model="showTaskDialog"
      persistent
      max-width="668px"
      style="overflow-y: hidden;"
    >
      <charter-tasks-details
        :external="true"
        :task-id="selectedTaskUuid"
        @close="closeTaskDialog"
        :key="selectedTaskUuid"
      ></charter-tasks-details>
    </v-dialog> -->
  </v-container>
</template>

<script>
import api from "@/utils/api.js";
import { Timeline, DataSet } from "vis-timeline/standalone";
import InlineBookingDialog from "@/components/dialogs/InlineBookingDialog";
import { mapGetters } from "vuex";
// import CharterTasksDetails from "@/views/tasks/TasksDetails";

export default {
  components: {
    InlineBookingDialog,
    // CharterTasksDetails
  },
  data: () => ({
    showDialog: false,
    selectedTaskUuid: null,
    selectedBookingUuid: null,
    timeline: null,
    showTimeline: false,
    showTaskDialog: false,
    allTasks: [],
    loading: false,
    boats: [],
    lastUpdateTime: [],
  }),
  created() {
    this.loadBookings();
  },

  computed: {
    ...mapGetters(["getMe"]),
  },
  methods: {
    closeTaskDialog() {
      this.showTaskDialog = false;
    },
    getColor() {
      return (
        "hsl(" +
        360 * Math.random() +
        "," +
        (25 + 70 * Math.random()) +
        "%," +
        (85 + 10 * Math.random()) +
        "%)"
      );
    },
    getBookingColor(element) {
      if (this.$moment(element.date_from) > this.$moment())
        return this.$store.getters.getTheme.light.primary;
      if (this.$moment(element.date_to) < this.$moment()) return "#c7c7c7";
      return this.$store.getters.getTheme.light.primary;
    },

    createNewBooking() {
      this.selectedBookingUuid = null;
      this.showDialog = true;
    },
    drawGraph(boats) {
      this.loading = true;
      let container = document.getElementById("bookings");
      const options = this.setOptions();

      if (this.timeline) {
        this.timeline.destroy();
      }

      let groups = new DataSet();
      let items = new DataSet();

      const formatedGroupData = this.formatGroupData(boats);
      const formatedBookingsData = this.formatBookingsData(boats);
      // const formatedTasksData = this.formatTasksData(boats);

      groups.add(formatedGroupData);
      items.add(formatedBookingsData);

      // items.add(formatedTasksData);

      let self = this;

      function handleTimelineRangechangedEvent(event) {
        let loadBookings = (params) => {
          self.lastUpdateTime = [params.start, params.end];

          api.getBookingBoats(params).then((resp) => {
            const formatedGroupData = this.formatGroupData(resp.data);
            const formatedBookingsData = this.formatBookingsData(resp.data);
            // const formatedTasksData = this.formatTasksData(resp.data);
            // items.add(self.mockData);
            groups.add(formatedGroupData);
            items.add(formatedBookingsData);
            // items.add(formatedTasksData);
            // this.boats = resp.data;
            // this.drawGraph(resp.data);
          });
        };

        let eventStart = self.$moment(event.start);
        let eventEnd = self.$moment(event.end);
        let startLastUpdate = self.$moment(self.lastUpdateTime[0]);
        let endLastUpdate = self.$moment(self.lastUpdateTime[1]);

        if (eventEnd.diff(endLastUpdate) >= 0) {
          let params = {
            autocomplete: true,
            disable_pagination: true,
            start: self.$moment(endLastUpdate).toISOString(),
            end: self
              .$moment(endLastUpdate)
              .add(30, "days")
              .toISOString(),
          };
          loadBookings(params);
        }

        if (eventStart.diff(startLastUpdate) <= 0) {
          let params = {
            autocomplete: true,
            disable_pagination: true,
            start: self.$moment(startLastUpdate).toISOString(),
            end: self
              .$moment(startLastUpdate)
              .subtract(30, "days")
              .toISOString(),
          };
          loadBookings(params);
        }
      }

      this.timeline = new Timeline(container, items, options);
      this.timeline.setGroups(groups);
      this.timeline.on("click", this.handleTimelineClickEvent);
      // this.timeline.on("rangechanged", handleTimelineRangechangedEvent);
      this.showTimeline = true;

      setTimeout(function() {
        this.loading = false;
      }, 0);
    },

    handleTimelineClickEvent(properties) {
      if (properties.item && this.allTasks.includes(properties.item)) {
        // this.selectedTaskUuid = properties.item;
        // this.showTaskDialog = true; // show task dialog
        console.error("Task dialog is still available");
      } else if (properties.item) {
        this.selectedBookingUuid = properties.item;
        this.showDialog = true; // show bookings dialog
      }
    },

    formatBookingsData(boats) {
      let bookings = [];
      boats.forEach((boat) => {
        boat.bookings.forEach((el, i) => {
          bookings.push({
            id: el.uuid,
            customId: "bookings",
            group: el.crm_boat.uuid,
            start: el.date_from,
            end: el.date_to,
            type: "range",
            content: `<span>${el.frontend_label}</span>`,
            // subgroup: "sg_1",
            // subgroupOrder: 1,
            style:
              "background-color:" +
              this.getBookingColor(el) +
              "; border: 0px; height:30px;color: white;",
          });
        });
      });
      return bookings;
    },

    formatGroupData(boats) {
      return boats.map((boat, index) => {
        return {
          id: boat.uuid,
          content: this.formatBoatName(boat),
          style: "height:100px",
          // subgroupStack: { sg_1: true, sg_2: true },
          // subgroupOrder: function(a, b) {
          //   return a.subgroup - b.subgroup;
          // },
        };
      });
    },

    setOptions() {
      return {
        start: this.$moment().subtract(3, "days"),
        end: this.$moment().add(4, "days"),
        // groupHeightMode: "fitItems",
        zoomMax: 2029800000,
        zoomMin: 2029800000,
        zoomable: false,
        margin: {
          item: 20,
          axis: 10,
        },
        // dataAttributes: "all",
      };
    },

    // formatTasksData(boats) {
    //   let tasks = [];
    //   boats.forEach((boat) => {
    //     if (boat.all_tasks === null) {
    //       return;
    //     }

    //     boat.all_tasks.forEach((task, i) => {
    //       this.allTasks.push(task.uuid);
    //       if (task.due_date !== null) {
    //         let duration = task.duration ? task.duration / 60 : 24 / 60;
    //         let start = new Date(task.due_date);

    //         start.setHours(start.getHours() - duration);

    //         let color = task.completed ? "#529e74" : "#c64f5f";

    //         let percentage = parseInt(
    //           (task.done_subtasks / task.all_subtasks) * 100
    //         );

    //         let task_string = "Task ";

    //         if (task.name) {
    //           // task_string = ` (${percentage}%) ${task.name}`;
    //           task_string = ` ${task.name}`;
    //         } else if (task.task_type) {
    //           let ttype = task.task_type.includes("CHECKIN")
    //             ? "Checkin"
    //             : "Checkout";
    //           task_string = ` ${ttype}`;
    //           // task_string = ` (${percentage}%) ${ttype}`;
    //         }

    //         tasks.push({
    //           id: task.uuid,
    //           customId: "tasks",
    //           group: boat.uuid,
    //           start: start,
    //           end: task.due_date,
    //           type: "range",
    //           content: task_string, //el.guest_email,
    //           subgroup: "sg_1",
    //           subgroupOrder: 1,
    //           style:
    //             "background-color:" + color + "; border: 0px; color: #ffffff;",
    //         });
    //       }
    //     });
    //   });
    //   return tasks;
    // },

    loadBookings(params = {}) {
      if (Object.keys(params).length === 0) {
        params = {
          autocomplete: true,
          disable_pagination: true,
          start: this.$moment()
            .subtract(30, "days")
            .toISOString(),
          end: this.$moment()
            .add(30, "days")
            .toISOString(),
        };
      }

      this.lastUpdateTime = [params.start, params.end];

      api.getBookingBoats(params).then((resp) => {
        this.boats = resp.data;
        this.drawGraph(resp.data);
      });
    },

    formatBoatName(item) {
      // let percentageOfCompletion = "";
      // let link = "";

      // if (item.recent_task !== null) {
      //   link = window.location.origin + "/tasks/tasks/" + item.recent_task.uuid;
      // }

      // if (item.status.toLowerCase() !== "rented") {
      //   percentageOfCompletion =
      //     item.recent_task !== null
      //       ? Math.round(
      //           (item.recent_task.done_subtasks * 100) /
      //             item.recent_task.all_subtasks
      //         ) + " %"
      //       : "";
      // }

      // ${
      //   this.getMe.dealerships.length > 1
      //     ? item.managing_partner.name
      //     : ""
      // }

      return `<div style="position:relative;width: 200px;height: 50px;">
                  <span style="position: absolute; width: 100px;">
                    <b style="margin-right:20px; margin-left:20px;">${item.boat_name}</b> <br/>               
                    ${item.managing_partner.name}
                  </span>
                </div>`;

      // return `<div style="position:relative;width: 200px;height: 50px;">
      //             <span style="position: absolute; width: 100px;">
      //               <b>${item.boat_name}</b>
      //             </span>
      //             <span style="position: absolute; right: 0; width: 85px;">
      //             <span style="text-transform: capitalize;" >
      //               ${item.status.toLowerCase()}
      //             </span>
      //             </span>
      //             <br/>
      //             <a href="${link}" style="position: absolute; right: 0; width: 85px;">${percentageOfCompletion}</a>
      //           </div>`;
    },

    handleBoatSelection(selection) {
      if (selection === null) {
        this.loadBookings();
        return;
      }

      let selectedBoat = this.boats.find(
        (boat) => boat.uuid === selection.uuid
      );
      this.drawGraph([selectedBoat]);
    },
  },
};
</script>
<style>
.vis-label .vis-inner {
  min-height: 50px;
}

.vis-foreground:nth-child(even) .vis-group:nth-child(even) {
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid #bfbfbf;
  background-color: #3232;
}

.vis-labelset .vis-label:nth-child(even) {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  color: #4d4d4d;
  box-sizing: border-box;
  background-color: #3232;
}
</style>
